import react, { Component } from "react";
import Footer from "./footer";
import Header from "./Header";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { addSettings, addMsaInfo } from "../../actions";

import LefeSideBar from "./leftSideBar";
import Dashboard from "../../Pages/Client/Dashboard";
import Services from "../../Pages/Client/Services";
import Agreements from "../../Pages/Client/Agreements";
import UnsignedAgreements from "../../Pages/Client/Agreements/unsignedagreements";
import Pdfdownload from "../../Pages/Client/Agreements/pdfdownload";

import Help from "../../Pages/Client/Help";
import CreditApp from "../../Pages/Client/CreditApp";
import AddCreditApp from "../../Pages/Client/CreditApp/addCreditApp";

import SubClients from "../../Pages/Client/SubClients";
import AddSubClient from "../../Pages/Client/SubClients/addsubclient";

import AccountActivity from "../../Pages/Client/AccountActivity";
import KycForm from "../../Pages/Client/KycForm";

import BillingInformation from "../../Pages/Client/Billing";
import Invoices from "../../Pages/Client/Billing/Invoices";
import PaymentHistory from "../../Pages/Client/Billing/PaymentHistory";
import Pricing from "../../Pages/Client/Billing/Pricing";

import Loaders from "../../Pages/Client/Loaders";

import Cdr from "../../Pages/Client/Reporting";
import SmsRecords from "../../Pages/Client/Reporting/SmsRecords";
import MouStats from "../../Pages/Client/Reporting/MouStats";
import BalanceUsage from "../../Pages/Client/Reporting/BalanceUsage";

import MSA from "../../Pages/Reseller/MSA";
import DashboardLoader from "../ContentLoaders/dashboard_loader";
import ErrorPage from "../Errors/404";

import { getAccountData, profileUpdate } from "../../config/client_api_calls";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../config/config";
import { REPLACECONTENT, ValidateEmail, normalize } from "../../config/utility";
import HelperClass from "./../../config/helperClass";
import Agreement from "./agreement_new";

import TextField from "../InputFields/TextField";

const auth = new HelperClass();

class Main extends Component {
  constructor(props) {
    super(props);

    if (auth.isClientLogin() === false) {
      auth.clientLogout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      is_msa_signed: false,
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#ffffff",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: "",
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      confirm_password: "",
      errorAddress: "",
      address: "",
      errorCity: "",
      city: "",
      errorState: "",
      states: "",
      errorZip: "",
      zip: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      routingUrl: "",
    };
  }

  async componentDidMount() {
    this.setState({
      comp_name: auth.getCompNameClient(),
      name: auth.getNameClient(),
      email: auth.getEmailClient(),
      phone: auth.getPhoneClient(),
      title: auth.getTitleClient() === false ? "" : auth.getTitleClient(),
    });
    this.defaultChecks();
  }

  formUpdate = async () => {
    let {
      name,
      email,
      comp_name,
      title,
      phone,
      address,
      city,
      states,
      zip,
      password,
      confirm_password,
    } = this.state;

    let is_validated = true;
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
      });
      is_validated = false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
      });
      is_validated = false;
    } else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (password !== "") {
      if (password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (confirm_password === "") {
        this.setState({
          errorPass: "error",

          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (confirm_password.length < 8) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (password.trim() != confirm_password.trim()) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        errorTitle: "",
        disabled: true,
      });

      let apiResponce = await profileUpdate(
        auth.getClientAccount(),
        auth.getClientToken(),
        name,
        title,
        comp_name,
        phone,
        address,
        city,
        states,
        zip,
        password
      );
      console.log(apiResponce);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        Cookies.set(
          "client_comp_nme__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["comp_name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_name__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_phone__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["phone"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_title__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["title"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        this.setState({
          successMessage: "Profile updated successfully.",
          disabled: false,
        });

        if(this.state.is_msa_signed === false){
          window.location.reload();
        }
        setTimeout(async function () {
          window.$("#modalFormProfile").modal("hide");
        }, 2000);
      
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try later.",
          disabled: false,
        });
        return false;
      } else {
        auth.clientLogout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  agreementSigned = () => {
    this.setState({ is_msa_signed: true });
  };

  defaultChecks = async () => {
    let apiData = await getAccountData(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Agreement DATA: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (
      apiData.data.status === 404 ||
      (apiData.data.data && apiData.data.data.length === 0)
    ) {
      console.log("First 404 Error");
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.props.addSettings(apiData.data.resellers_setting);
      this.props.addMsaInfo(apiData.data.agreement);

      this.setState({
        address: apiData.data.data[0]["address"],
        city: apiData.data.data[0]["city"],
        states: apiData.data.data[0]["state"],
        zip: apiData.data.data[0]["zip"],
        routingUrl: apiData.data.url,
      });
      if (apiData.data.agreement === "No") {
        this.setState({
          is_msa_signed: false,
          defaultLoader: false,
        });
      } else if (apiData.data.agreement === "Yes") {
        this.setState({
          is_msa_signed: true,
          defaultLoader: false,
        });
      }
    } else if (
      (apiData.data.status === 200 &&
        apiData.data.data[0]["isVerified"] === "No") ||
      apiData.data.data[0]["account_status"] !== "active"
    ) {
      auth.clientLogout();
    } else {
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  };

  pageView = () => {
    console.log("PAGE PROP " + this.state.view);
    if (this.state.view === "dashboard") {
      return <Dashboard props={this.props} />;
    } else if (this.state.view === "services") {
      return <Services props={this.props} />;
    } else if (this.state.view === "Pdfdownload") {
      return <Pdfdownload props={this.props} />;
    } else if (this.state.view === "UnsignedAgreements") {
      return <UnsignedAgreements props={this.props} />;
    } else if (this.state.view === "SubClients") {
      return <SubClients props={this.props} />;
    } else if (this.state.view === "AddSubClient") {
      return <AddSubClient props={this.props} />;
    } else if (this.state.view === "help") {
      return <Help props={this.props} />;
    } else if (this.state.view === "KycForm") {
      return <KycForm props={this.props} />;
    } else if (this.state.view === "AccountActivity") {
      return <AccountActivity props={this.props} />;
    } else if (this.state.view === "BillingInformation") {
      return <BillingInformation props={this.props} />;
    } else if (this.state.view === "Invoices") {
      return <Invoices props={this.props} />;
    } else if (this.state.view === "PaymentHistory") {
      return <PaymentHistory props={this.props} />;
    } else if (this.state.view === "Pricing") {
      return <Pricing props={this.props} />;
    } else if (this.state.view === "Loaders") {
      return <Loaders props={this.props} />;
    } else if (this.state.view === "Cdr") {
      return <Cdr props={this.props} />;
    } else if (this.state.view === "SmsRecords") {
      return <SmsRecords props={this.props} />;
    } else if (this.state.view === "MouStats") {
      return <MouStats props={this.props} />;
    } else if (this.state.view === "BalanceUsage") {
      return <BalanceUsage props={this.props} />;
    } else if (this.state.view === "CreditApp") {
      return <CreditApp props={this.props} />;
    } else if (this.state.view === "AddCreditApp") {
      return <AddCreditApp props={this.props} />;
    } else if (this.state.view === "agreements") {
      return <Agreements props={this.props} />;
    }
    if (this.state.view === "msa_signing") {
      return <MSA />;
    } else {
      return <ErrorPage />;
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalFormProfile").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            {this.state.defaultLoader === true ? (
              <div>
                <div>
                  {this.state.is_msa_signed === true ? (
                    <LefeSideBar
                      mainProps={this.props}
                      routingUrl={this.state.routingUrl}
                      {...this.props}
                    />
                  ) : null}
                </div>
                {/*<div>
                  <DashboardLoader />
            </div>*/}
              </div>
            ) : (
              <div>
                {this.state.is_msa_signed === true ? (
                  <LefeSideBar
                    mainProps={this.props}
                    routingUrl={this.state.routingUrl}
                    {...this.props}
                  />
                ) : null}
              </div>
            )}

            <div className="nk-wrap ">
              <Header
                isNdaSigned={this.state.is_msa_signed}
                modalOpen={() => {
                  this.profileView();
                }}
                mainProps={this.props}
              />

              {this.state.is_msa_signed === true ? (
                this.pageView()
              ) : (
                <Agreement
                  backgroundColor={this.state.bgColor}
                  vendor_signor_signature_file={
                    this.state.vendor_signor_signature_file
                  }
                  mainHistory={this.props}
                  agreementSigned={() => this.agreementSigned()}
                />
              )}
              {/* MODAL EDIT PROFILE START */}
              <div className="modal fade" tabIndex="-1" id="modalFormProfile">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Profile</h5>
                      <a
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Company Name"
                              error={this.state.errorCompName}
                              id="comp_name"
                              name="comp_name"
                              value={this.state.comp_name}
                              placeholder="Company Name"
                              change={this.handleChange}
                              type="text"
                              
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Full Name"
                              error={this.state.errorName}
                              id="name"
                              name="name"
                              value={this.state.name}
                              placeholder="Full Name"
                              change={this.handleChange}
                              
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Title"
                              error={this.state.errorTitle}
                              id="title"
                              name="title"
                              value={this.state.title}
                              placeholder="Title"
                              change={this.handleChange}
                             
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Email"
                              type="text"
                              error={this.state.errorEmail}
                              id="email"
                              name="email"
                              value={this.state.email}
                              placeholder="Enter your email address"
                              change={this.handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Phone"
                              type="text"
                              error={this.state.errorPhone}
                              id="phone"
                              name="phone"
                              value={this.state.phone}
                              placeholder="Enter your phone"
                              change={this.handleChange}
                             
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Address"
                              type="text"
                              error={this.state.errorAddress}
                              id="address"
                              name="address"
                              value={this.state.address}
                              placeholder="Enter your address"
                              change={this.handleChange}
                            
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="City"
                              type="text"
                              error={this.state.errorCity}
                              id="city"
                              name="city"
                              value={this.state.city}
                              placeholder="Enter your city"
                              change={this.handleChange}
                             
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="State"
                              type="text"
                              error={this.state.errorState}
                              id="states"
                              name="states"
                              value={this.state.states}
                              placeholder="Enter your state"
                              change={this.handleChange}
                             
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Zip Code"
                              type="text"
                              error={this.state.errorZip}
                              id="zip"
                              name="zip"
                              value={this.state.zip}
                              placeholder="Enter your zip code"
                              change={this.handleChange}
                             
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Password"
                              type="password"
                              error={this.state.errorPass}
                              id="password"
                              name="password"
                              value={this.state.password}
                              placeholder="Enter your password"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Confirm Password"
                              type="password"
                              error={this.state.errorPass}
                              id="confirm_password"
                              name="confirm_password"
                              value={this.state.confirm_password}
                              placeholder="Enter confirm password"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">&nbsp;</div>
                        <div style={{ clear: "both" }}></div>
                        {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-lg"
                            onClick={() => {
                              this.formUpdate();
                            }}
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                              float: "right",
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END MODAL EDIT PROFILE */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
    addMsaInfo: (payload) => dispatch(addMsaInfo(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
