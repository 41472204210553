import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
const auth = new HelperClass();
export class Step6 extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  first = (e) => {
    e.preventDefault();
    this.props.firstStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    this.setState({
      tableLoader: true,
      disabled: true,
    });
    //this.props.VerifyConnection();
    setTimeout(() => {
      this.setState({
        tableLoader: false,
        disabled: false,
      });
      this.continue();
    }, 2000);
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  resendEmail = () => {};

  render() {
    //const { VerifyConnection } = this.props;
    const { values, handleChange } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "10%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      {/*<li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Company Info</div>
                            <div className="sub-text">
                              Company name and 499 Filer ID
                            </div>
                          </div>
                        </div>
                      </li>*/}
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Contact Info</div>
                            <div className="sub-text">
                              Other contact information
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Other Info</div>
                            <div className="sub-text">
                              Number of employes in the company
                            </div>
                          </div>
                        </div>
                      </li>*/}
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li className="current">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        {values.errorMessage !== "" ? (
                          <div className="nk-stepper-step text-center active">
                            <h5 className="title mb-2">Oops!</h5>
                            {values.errorMessage !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{values.errorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {/*<div className="gfx w-50 mx-auto">
                              <img
                                className="hide_this_img image"
                                src="../../../assets/images/animation_success.gif"
                              />
                            </div>*/}
                          </div>
                        ) : null}
                        {values.successMessage !== "" ? (
                          <div className="nk-stepper-step text-center active">
                            <h5 className="title mb-2">Your Are Done!</h5>

                            {values.successMessage !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{values.successMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            <div className="gfx w-50 mx-auto">
                              <img
                                className="hide_this_img image"
                                src="../../../assets/images/animation_success.gif"
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                        <li className="step-prev">
                          <button
                            type="button"
                            className="btn btn-dim btn-primary"
                            onClick={this.first}
                          >
                            First Step
                          </button>
                        </li>
                        {values.successMessage !== "" ? (
                          <li className="step-next">
                            <button
                              className="btn btn-dim btn-primary"
                              onClick={() => this.resendEmail()}
                              disabled={this.state.disableResendBtn}
                              id="resendBtn"
                            >
                              Resend Email
                            </button>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step6;
